import * as React from "react"
import Layout from "../components/layout-no-header"
import logo from "../images/logo_black.svg"

const Datenschutz = () => (
  <Layout>
    <nav className="mb-5 pt-10 flex justify-center">
      <div className="block md:hidden">
        <a href="/">
          <img src={logo} alt="Logo" width={200} height={39} />
        </a>
      </div>
      <div className="hidden md:block">
        <a href="/">
          <img src={logo} alt="Logo" width={250} height={49} />
        </a>
      </div>
    </nav>
    <div className="w-4/5 mx-auto text-center my-5 mb-5 leading-tight">
      <h1>Datenschutz&shy;erklärung</h1>
    </div>
    <section className="mx-auto flex w-11/12 md:w-3/4 lg:w-1/2 pb-10">
      <div className="flex-auto text-base">
        <p>
          Verantwortlicher im Sinne der Datenschutz­gesetze, insbesondere der
          EU-Datenschutz­grund­verordnung (DSGVO), ist:
        </p>
        <p>
          <strong>
            AROUND IT-Security GmbH
            <br />
            Weingärterstr. 17
            <br />
            74912 Kirchardt
          </strong>
        </p>
        <br />
        <h2>Ihre Betroffenenrechte</h2>
        <p>
          Unter den angegebenen Kontaktdaten können Sie jederzeit folgende
          Rechte ausüben:
        </p>
        <ul>
          <li>
            Auskunft über Ihre bei uns gespeicherten Daten und deren
            Verarbeitung (Art. 15 DSGVO),
          </li>
          <li>
            Berichtigung unrichtiger personenbezogener Daten (Art. 16 DSGVO),
          </li>
          <li>Löschung Ihrer bei uns gespeicherten Daten (Art. 17 DSGVO),</li>
          <li>
            Einschränkung der Datenverarbeitung, sofern wir Ihre Daten aufgrund
            gesetzlicher Pflichten noch nicht löschen dürfen (Art. 18 DSGVO),
          </li>
          <li>
            Widerspruch gegen die Verarbeitung Ihrer Daten bei uns (Art. 21
            DSGVO) und
          </li>
          <li>
            Datenübertragbarkeit, sofern Sie in die Datenverarbeitung
            eingewilligt haben oder einen Vertrag mit uns abgeschlossen haben
            (Art. 20 DSGVO).
          </li>
        </ul>
        <p>
          Sofern Sie uns eine Einwilligung erteilt haben, können Sie diese
          jederzeit mit Wirkung für die Zukunft widerrufen.
        </p>
        <p>
          Sie können sich jederzeit mit einer Beschwerde an eine
          Aufsichtsbehörde wenden, z. B. an die zuständige Aufsichtsbehörde des
          Bundeslands Ihres Wohnsitzes oder an die für uns als verantwortliche
          Stelle zuständige Behörde.
        </p>
        <p>
          Eine Liste der Aufsichtsbehörden (für den nichtöffentlichen Bereich)
          mit Anschrift finden Sie unter:{" "}
          <a
            href="https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html"
            target="_blank"
            rel="noreferrer"
          >
            Hier klicken
          </a>
          .
        </p>
        <br />
        <p>
          <strong>
            Erfassung allgemeiner Informationen beim Besuch unserer Website
          </strong>
        </p>
        <br />
        <p>
          <strong>Art und Zweck der Verarbeitung:</strong>
        </p>
        <p>
          Wenn Sie auf unsere Website zugreifen, d.h., wenn Sie sich nicht
          registrieren oder anderweitig Informationen übermitteln, werden
          automatisch Informationen allgemeiner Natur erfasst. Diese
          Informationen (Server-Logfiles) beinhalten etwa die Art des
          Webbrowsers, das verwendete Betriebssystem, den Domainnamen Ihres
          Internet-Service-Providers, Ihre IP-Adresse und ähnliches.
        </p>
        <p>Sie werden insbesondere zu folgenden Zwecken verarbeitet:</p>
        <ul>
          <li>
            Sicherstellung eines problemlosen Verbindungsaufbaus der Website,
          </li>
          <li>Sicherstellung einer reibungslosen Nutzung unserer Website,</li>
          <li>Auswertung der Systemsicherheit und -stabilität sowie</li>
          <li>zur Optimierung unserer Website.</li>
        </ul>
        <p>
          Wir verwenden Ihre Daten nicht, um Rückschlüsse auf Ihre Person zu
          ziehen. Informationen dieser Art werden von uns ggfs. anonymisiert
          statistisch ausgewertet, um unseren Internetauftritt und die
          dahinterstehende Technik zu optimieren.
        </p>
        <br />
        <p>
          <strong>Rechtsgrundlage und berechtigtes Interesse:</strong>
        </p>
        <p>
          Die Verarbeitung erfolgt gemäß Art. 6 Abs. 1 lit. f DSGVO auf Basis
          unseres berechtigten Interesses an der Verbesserung der Stabilität und
          Funktionalität unserer Website.
        </p>
        <br />
        <p>
          <strong>Empfänger:</strong>
        </p>
        <p>
          Empfänger der Daten sind ggf. technische Dienstleister, die für den
          Betrieb und die Wartung unserer Webseite als Auftragsverarbeiter tätig
          werden.
        </p>
        <br />
        <p>
          <strong>Drittlandtransfer:</strong>
        </p>
        <p>
          Die erhobenen Daten werden ggfs. in folgende Drittländer übertragen:
          <br />
          Ja
        </p>
        <br />
        <p>
          <strong>Speicherdauer:</strong>
        </p>
        <p>
          Die Daten werden gelöscht, sobald diese für den Zweck der Erhebung
          nicht mehr erforderlich sind. Dies ist für die Daten, die der
          Bereitstellung der Website dienen, grundsätzlich der Fall, wenn die
          jeweilige Sitzung beendet ist.
        </p>
        <p>
          Im Falle der Speicherung der Daten in Logfiles ist dies nach
          spätestens 14 Tagen der Fall. Eine darüberhinausgehende Speicherung
          ist möglich. In diesem Fall werden die IP-Adressen der Nutzer
          anonymisiert, sodass eine Zuordnung des aufrufenden Clients nicht mehr
          möglich ist.
        </p>
        <br />
        <p>
          <strong>Bereitstellung vorgeschrieben oder erforderlich:</strong>
        </p>
        <p>
          Die Bereitstellung der vorgenannten personenbezogenen Daten ist weder
          gesetzlich noch vertraglich vorgeschrieben. Ohne die IP-Adresse ist
          jedoch der Dienst und die Funktionsfähigkeit unserer Website nicht
          gewährleistet. Zudem können einzelne Dienste und Services nicht
          verfügbar oder eingeschränkt sein. Aus diesem Grund ist ein
          Widerspruch ausgeschlossen.
        </p>
        <br />
        <h2>Erbringung kostenpflichtiger Leistungen</h2>
        <br />
        <p>
          <strong>Art und Zweck der Verarbeitung:</strong>
        </p>
        <p>
          Zur Erbringung kostenpflichtiger Leistungen werden von uns zusätzliche
          Daten erfragt, wie z.B. Zahlungsangaben, um Ihre Bestellung ausführen
          zu können.
        </p>
        <br />
        <p>
          <strong>Rechtsgrundlage:</strong>
        </p>
        <p>
          Die Verarbeitung der Daten, die für den Abschluss des Vertrages
          erforderlich ist, basiert auf Art. 6 Abs. 1 lit. b DSGVO.
        </p>
        <br />
        <p>
          <strong>Empfänger:</strong>
        </p>
        <p>Empfänger der Daten sind ggf. Auftragsverarbeiter.</p>
        <br />
        <p>
          <strong>Speicherdauer:</strong>
        </p>
        <p>
          Wir speichern diese Daten in unseren Systemen, bis die gesetzlichen
          Aufbewahrungsfristen abgelaufen sind. Diese betragen grundsätzlich 6
          oder 10 Jahre aus Gründen der ordnungsmäßigen Buchführung und
          steuerrechtlichen Anforderungen.
        </p>
        <br />
        <p>
          <strong>Bereitstellung vorgeschrieben oder erforderlich:</strong>
        </p>
        <p>
          Die Bereitstellung Ihrer personenbezogenen Daten erfolgt freiwillig.
          Ohne die Bereitstellung Ihrer personenbezogenen Daten können wir Ihnen
          keinen Zugang auf unsere angebotenen Inhalte und Leistungen gewähren.
        </p>
        <br />
        <h2>Kommentar­funktion</h2>
        <br />
        <p>
          <strong>Art und Zweck der Verarbeitung:</strong>
        </p>
        <p>
          Wenn Nutzer Kommentare auf unserer Website hinterlassen, werden neben
          diesen Angaben auch der Zeitpunkt ihrer Erstellung und der zuvor durch
          den Websitebesucher gewählte Nutzername gespeichert. Dies dient
          unserer Sicherheit, da wir für widerrechtliche Inhalte auf unserer
          Webseite belangt werden können, auch wenn diese durch Benutzer
          erstellt wurden.
        </p>
        <br />
        <p>
          <strong>Rechtsgrundlage:</strong>
        </p>
        <p>
          Die Verarbeitung der als Kommentar eingegebenen Daten erfolgt auf der
          Grundlage eines berechtigten Interesses (Art. 6 Abs. 1 lit. f DSGVO).
        </p>
        <p>
          Durch Bereitstellung der Kommentarfunktion möchten wir Ihnen eine
          unkomplizierte Interaktion ermöglichen. Ihre gemachten Angaben werden
          zum Zwecke der Bearbeitung der Anfrage sowie für mögliche
          Anschlussfragen gespeichert.
        </p>
        <br />
        <p>
          <strong>Empfänger:</strong>
        </p>
        <p>Empfänger der Daten sind ggf. Auftragsverarbeiter.</p>
        <br />
        <p>
          <strong>Speicherdauer:</strong>
        </p>
        <p>
          Die Daten werden gelöscht, sobald diese für den Zweck der Erhebung
          nicht mehr erforderlich sind. Dies ist grundsätzlich der Fall, wenn
          die Kommunikation mit dem Nutzer abgeschlossen ist und das Unternehmen
          den Umständen entnehmen kann, dass der betroffene Sachverhalt
          abschließend geklärt ist. Wir behalten uns die Löschung ohne Angaben
          von Gründen und ohne vorherige oder nachträgliche Information vor.
        </p>
        <p>
          Außerdem können Sie Ihren Kommentar jederzeit durch uns löschen
          lassen. Schreiben Sie dafür bitte eine E-Mail an den unten
          aufgeführten Datenschutzbeauftragten bzw. die für den Datenschutz
          zuständige Person und übermitteln den Link zu Ihrem Kommentar sowie zu
          Identifikationszwecken die bei der Erstellung des Kommentars
          verwendete E-Mail-Adresse.
        </p>
        <br />
        <p>
          <strong>Bereitstellung vorgeschrieben oder erforderlich:</strong>
        </p>
        <p>
          Die Bereitstellung Ihrer personenbezogenen Daten erfolgt freiwillig.
          Ohne die Bereitstellung Ihrer personenbezogenen Daten können wir Ihnen
          keinen Zugang zu unserer Kommentarfunktion gewähren.
        </p>
        <br />
        <h2>Kontaktformular</h2>
        <br />
        <p>
          <strong></strong>
        </p>
        <strong>
          <p>
            <strong />
          </p>
        </strong>
        <br />
        <p>
          <strong>Art und Zweck der Verarbeitung:</strong>
        </p>
        <p>
          Die von Ihnen eingegebenen Daten werden zum Zweck der individuellen
          Kommunikation mit Ihnen gespeichert. Hierfür ist die Angabe einer
          validen E-Mail-Adresse sowie Ihres Namens erforderlich. Diese dient
          der Zuordnung der Anfrage und der anschließenden Beantwortung
          derselben. Die Angabe weiterer Daten ist optional.
        </p>
        <br />
        <p>
          <strong>Rechtsgrundlage:</strong>
        </p>
        <p>
          Die Verarbeitung der in das Kontaktformular eingegebenen Daten erfolgt
          auf der Grundlage eines berechtigten Interesses (Art. 6 Abs. 1 lit. f
          DSGVO).
        </p>
        <p>
          Durch Bereitstellung des Kontaktformulars möchten wir Ihnen eine
          unkomplizierte Kontaktaufnahme ermöglichen. Ihre gemachten Angaben
          werden zum Zwecke der Bearbeitung der Anfrage sowie für mögliche
          Anschlussfragen gespeichert.
        </p>
        <p>
          Sofern Sie mit uns Kontakt aufnehmen, um ein Angebot zu erfragen,
          erfolgt die Verarbeitung der in das Kontaktformular eingegebenen Daten
          zur Durchführung vorvertraglicher Maßnahmen (Art. 6 Abs. 1 lit. b
          DSGVO).
        </p>
        <br />
        <p>
          <strong>Empfänger:</strong>
        </p>
        <p>Empfänger der Daten sind ggf. Auftragsverarbeiter.</p>
        <br />
        <p>
          <strong>Speicherdauer:</strong>
        </p>
        <p>
          Daten werden spätestens 6 Monate nach Bearbeitung der Anfrage
          gelöscht.
        </p>
        <p>
          Sofern es zu einem Vertragsverhältnis kommt, unterliegen wir den
          gesetzlichen Aufbewahrungsfristen nach HGB und löschen Ihre Daten nach
          Ablauf dieser Fristen.
        </p>
        <br />
        <p>
          <strong>Bereitstellung vorgeschrieben oder erforderlich:</strong>
        </p>
        <p>
          Die Bereitstellung Ihrer personenbezogenen Daten erfolgt freiwillig.
          Wir können Ihre Anfrage jedoch nur bearbeiten, sofern Sie uns Ihren
          Namen, Ihre E-Mail-Adresse und den Grund der Anfrage mitteilen.
        </p>
        <br />
        <h2>Verwendung von Google Analytics</h2>
        <p>
          Soweit Sie ihre Einwilligung gegeben haben, wird auf dieser Website
          Google Analytics eingesetzt, ein Webanalysedienst der Google LLC, 1600
          Amphitheatre Parkway, Mountain View, CA 94043 USA (nachfolgend:
          „Google“). Google Analytics verwendet sog. „Cookies“, also
          Textdateien, die auf Ihrem Computer gespeichert werden und die eine
          Analyse der Benutzung der Webseite durch Sie ermöglichen. Die durch
          das Cookie erzeugten Informationen über Ihre Benutzung dieser Webseite
          werden in der Regel an einen Server von Google in den USA übertragen
          und dort gespeichert. Aufgrund der Aktivierung der IP-Anonymisierung
          auf diesen Webseiten, wird Ihre IP-Adresse von Google jedoch innerhalb
          von Mitgliedstaaten der Europäischen Union oder in anderen
          Vertragsstaaten des Abkommens über den Europäischen Wirtschaftsraum
          zuvor gekürzt. Nur in Ausnahmefällen wird die volle IP-Adresse an
          einen Server von Google in den USA übertragen und dort gekürzt. Die im
          Rahmen von Google Analytics von Ihrem Browser übermittelte IP-Adresse
          wird nicht mit anderen Daten von Google zusammengeführt.
        </p>
        <p>
          Nähere Informationen zu Nutzungsbedingungen und Datenschutz finden Sie
          unter{" "}
          <a
            href="https://www.google.com/analytics/terms/de.html%20und%20unter%20https://policies.google.com/?hl=de"
            target="_blank"
            rel="noreferrer"
          >
            Hier klicken
          </a>
          .
        </p>
        <p>
          Im Auftrag des Betreibers dieser Website wird Google diese
          Informationen benutzen, um Ihre Nutzung der Webseite auszuwerten, um
          Reports über die Webseitenaktivitäten zusammenzustellen und um weitere
          mit der Websitenutzung und der Internetnutzung verbundene
          Dienstleistungen gegenüber dem Webseitenbetreiber zu erbringen.
        </p>
        <p>
          Die von uns gesendeten und mit Cookies, Nutzerkennungen (z. B.
          User-ID) oder Werbe-IDs verknüpften Daten werden nach 14 Monaten
          automatisch gelöscht. Die Löschung von Daten, deren Aufbewahrungsdauer
          erreicht ist, erfolgt automatisch einmal im Monat.
        </p>
        <br />
        <p>
          <strong>Widerruf der Einwilligung:</strong>
        </p>
        <p>
          Sie können das Tracking durch Google Analytics auf unserer Website
          unterbinden, indem Sie{" "}
          <a
            href="https://www.einfachmehrkunden.com/datenschutzerklaerung/#"
            rel="noreferrer"
            target="_blank"
          >
            Hier klicken
          </a>
          . Dabei wird ein Opt-out-Cookie auf Ihrem Gerät installiert. Damit
          wird die Erfassung durch Google Analytics für diese Website und für
          diesen Browser zukünftig verhindert, solange das Cookie in Ihrem
          Browser installiert bleibt.
        </p>
        <p>
          Sie können darüber hinaus die Speicherung der Cookies durch eine
          entsprechende Einstellung Ihrer Browser-Software verhindern; wir
          weisen Sie jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls
          nicht sämtliche Funktionen dieser Website vollumfänglich werden nutzen
          können.
        </p>
        <p>
          Sie können darüber hinaus die Erfassung der durch das Cookie erzeugten
          und auf Ihre Nutzung der Webseite bezogenen Daten (inkl. Ihrer
          IP-Adresse) an Google sowie die Verarbeitung dieser Daten durch Google
          verhindern, indem sie das unter dem folgenden Link verfügbare
          Browser-Plugin herunterladen und installieren:{" "}
          <a
            href="https://tools.google.com/dlpage/gaoptout?hl=de"
            target="_blank"
            rel="noreferrer"
          >
            Hier klicken
          </a>
          .
        </p>
        <br />
        <h2>Verwendung von Scriptbibliotheken (Google Webfonts)</h2>
        <p>
          Um unsere Inhalte browserübergreifend korrekt und grafisch ansprechend
          darzustellen, verwenden wir auf dieser Website „Google Web Fonts“ der
          Google LLC (1600 Amphitheatre Parkway, Mountain View, CA 94043, USA;
          nachfolgend „Google“) zur Darstellung von Schriften.
        </p>
        <p>
          Weitere Informationen zu Google Web Fonts finden Sie unter{" "}
          <a
            href="https://developers.google.com/fonts/faq"
            target="_blank"
            rel="noreferrer"
          >
            Hier klicken
          </a>{" "}
          und in der Datenschutzerklärung von Google:{" "}
          <a
            href="https://www.google.com/policies/privacy/"
            target="_blank"
            rel="noreferrer"
          >
            Hier klicken
          </a>
          .
        </p>
        <br />
        <h2>Verwendung von Google Maps</h2>
        <p>
          Auf dieser Website nutzen wir das Angebot von Google Maps. Google Maps
          wird von Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA
          94043, USA (nachfolgend „Google“) betrieben. Dadurch können wir Ihnen
          interaktive Karten direkt in der Webseite anzeigen und ermöglichen
          Ihnen die komfortable Nutzung der Karten-Funktion.
          <br />
          Nähere Informationen über die Datenverarbeitung durch Google können
          Sie den Google-Datenschutzhinweisen entnehmen:{" "}
          <a
            href="https://policies.google.com/privacy"
            target="_blank"
            rel="noreferrer"
          >
            Hier klicken
          </a>
          . Dort können Sie im Datenschutzcenter auch Ihre persönlichen
          Datenschutz-Einstellungen verändern.
        </p>
        <p>
          Ausführliche Anleitungen zur Verwaltung der eigenen Daten im
          Zusammenhang mit Google-Produkten finden Sie hier:{" "}
          <a
            href="https://www.dataliberation.org/"
            target="_blank"
            rel="noreferrer"
          >
            Hier klicken
          </a>
        </p>
        <p>
          Durch den Besuch der Website erhält Google Informationen, dass Sie die
          entsprechende Unterseite unserer Webseite aufgerufen haben. Dies
          erfolgt unabhängig davon, ob Google ein Nutzerkonto bereitstellt, über
          das Sie eingeloggt sind, oder ob keine Nutzerkonto besteht. Wenn Sie
          bei Google eingeloggt sind, werden Ihre Daten direkt Ihrem Konto
          zugeordnet.
        </p>
        <p>
          Wenn Sie die Zuordnung in Ihrem Profil bei Google nicht wünschen,
          müssen Sie sich vor Aktivierung des Buttons bei Google ausloggen.
          Google speichert Ihre Daten als Nutzungsprofile und nutzt sie für
          Zwecke der Werbung, Marktforschung und/oder bedarfsgerechter
          Gestaltung seiner Websites. Eine solche Auswertung erfolgt
          insbesondere (selbst für nicht eingeloggte Nutzer) zur Erbringung
          bedarfsgerechter Werbung und um andere Nutzer des sozialen Netzwerks
          über Ihre Aktivitäten auf unserer Website zu informieren. Ihnen steht
          ein Widerspruchsrecht zu gegen die Bildung dieser Nutzerprofile, wobei
          Sie sich zur Ausübung dessen an Google richten müssen.
        </p>
        <br />
        <p>
          <strong>Widerruf der Einwilligung:</strong>
        </p>
        <p>
          Vom Anbieter wird derzeit keine Möglichkeit für einen einfachen
          Opt-out oder ein Blockieren der Datenübertragung angeboten. Wenn Sie
          eine Nachverfolgung Ihrer Aktivitäten auf unserer Website verhindern
          wollen, widerrufen Sie bitte im Cookie-Consent-Tool Ihre Einwilligung
          für die entsprechende Cookie-Kategorie oder alle technisch nicht
          notwendigen Cookies und Datenübertragungen. In diesem Fall können Sie
          unsere Website jedoch ggfs. nicht oder nur eingeschränkt nutzen.
        </p>
        <br />
        <h2>Eingebettete YouTube-Videos</h2>
        <p>
          Auf unserer Website betten wir YouTube-Videos ein. Betreiber der
          entsprechenden Plugins ist die YouTube, LLC, 901 Cherry Ave., San
          Bruno, CA 94066, USA (nachfolgend „YouTube“). Die YouTube, LLC ist
          einer Tochtergesellschaft der Google LLC, 1600 Amphitheatre Pkwy,
          Mountain View, CA 94043, USA (nachfolgend „Google“). Wenn Sie eine
          Seite mit dem YouTube-Plugin besuchen, wird eine Verbindung zu Servern
          von YouTube hergestellt. Dabei wird YouTube mitgeteilt, welche Seiten
          Sie besuchen. Wenn Sie in Ihrem YouTube-Account eingeloggt sind, kann
          YouTube Ihr Surfverhalten Ihnen persönlich zuzuordnen. Dies verhindern
          Sie, indem Sie sich vorher aus Ihrem YouTube-Account ausloggen.
        </p>
        <p>
          Wird ein YouTube-Video gestartet, setzt der Anbieter Cookies ein, die
          Hinweise über das Nutzerverhalten sammeln.
        </p>
        <p>
          Weitere Informationen zu Zweck und Umfang der Datenerhebung und ihrer
          Verarbeitung durch YouTube erhalten Sie in den Datenschutzerklärungen
          des Anbieters, Dort erhalten Sie auch weitere Informationen zu Ihren
          diesbezüglichen Rechten und Einstellungsmöglichkeiten zum Schutze
          Ihrer Privatsphäre (
          <a
            href="https://policies.google.com/privacy"
            rel="noreferrer"
            target="_blank"
          >
            Hier klicken
          </a>
          ).
        </p>
        <br />
        <p>
          <strong>Widerruf der Einwilligung:</strong>
        </p>
        <p>
          Vom Anbieter wird derzeit keine Möglichkeit für einen einfachen
          Opt-out oder ein Blockieren der Datenübertragung angeboten. Wenn Sie
          eine Nachverfolgung Ihrer Aktivitäten auf unserer Website verhindern
          wollen, widerrufen Sie bitte im Cookie-Consent-Tool Ihre Einwilligung
          für die entsprechende Cookie-Kategorie oder alle technisch nicht
          notwendigen Cookies und Datenübertragungen. In diesem Fall können Sie
          unsere Website jedoch ggfs. nicht oder nur eingeschränkt nutzen.
        </p>
        <br />
        <h2>Google AdWords</h2>
        <p>
          Unsere Website nutzt das Google Conversion-Tracking.
          Betreibergesellschaft der Dienste von Google AdWords ist die Google
          LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA. Sind Sie
          über eine von Google geschaltete Anzeige auf unsere Webseite gelangt,
          wird von Google Adwords ein Cookie auf Ihrem Rechner gesetzt. Das
          Cookie für Conversion-Tracking wird gesetzt, wenn ein Nutzer auf eine
          von Google geschaltete Anzeige klickt.
        </p>
        <p>
          Besucht der Nutzer bestimmte Seiten unserer Website und das Cookie ist
          noch nicht abgelaufen, können wir und Google erkennen, dass der Nutzer
          auf die Anzeige geklickt hat und zu dieser Seite weitergeleitet wurde.
          Jeder Google AdWords-Kunde erhält ein anderes Cookie. Cookies können
          somit nicht über die Websites von AdWords-Kunden nachverfolgt werden.
          Die mithilfe des Conversion-Cookies eingeholten Informationen dienen
          dazu, Conversion-Statistiken für AdWords-Kunden zu erstellen, die sich
          für Conversion-Tracking entschieden haben. Die Kunden erfahren die
          Gesamtanzahl der Nutzer, die auf ihre Anzeige geklickt haben und zu
          einer mit einem Conversion-Tracking-Tag versehenen Seite
          weitergeleitet wurden. Sie erhalten jedoch keine Informationen, mit
          denen sich Nutzer persönlich identifizieren lassen.
        </p>
        <p>
          Nähere Informationen über die Datenverarbeitung durch Google können
          Sie den Google-Datenschutzhinweisen entnehmen:{" "}
          <a
            href="https://policies.google.com/privacy"
            target="_blank"
            rel="noreferrer"
          >
            Hier klicken
          </a>
          . Dort können Sie im Datenschutzcenter auch Ihre persönlichen
          Datenschutz-Einstellungen verändern.
        </p>
        <br />
        <p>
          <strong>Widerruf der Einwilligung:</strong>
        </p>
        <p>
          Vom Anbieter wird derzeit keine Möglichkeit für einen einfachen
          Opt-out oder ein Blockieren der Datenübertragung angeboten. Wenn Sie
          eine Nachverfolgung Ihrer Aktivitäten auf unserer Website verhindern
          wollen, widerrufen Sie bitte im Cookie-Consent-Tool Ihre Einwilligung
          für die entsprechende Cookie-Kategorie oder alle technisch nicht
          notwendigen Cookies und Datenübertragungen. In diesem Fall können Sie
          unsere Website jedoch ggfs. nicht oder nur eingeschränkt nutzen.
        </p>
        <br />
        <p>
          <strong>Einsatz von Google Remarketing</strong>
        </p>
        <p>
          Diese Website verwendet die Remarketing-Funktion der Google Inc.
          Betreibergesellschaft der Dienste von Google Remarketing ist die
          Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA
          (nachfolgend „Google“).
        </p>
        <p>
          Die Funktion dient dazu, Websitebesuchern innerhalb des
          Google-Werbenetzwerks interessenbezogene Werbeanzeigen zu
          präsentieren. Im Browser des Websitebesuchers wird ein sog. „Cookie“
          gespeichert, der es ermöglicht, den Besucher wiederzuerkennen, wenn
          dieser Webseiten aufruft, die dem Werbenetzwerk von Google angehören.
          Auf diesen Seiten können dem Besucher Werbeanzeigen präsentiert
          werden, die sich auf Inhalte beziehen, die der Besucher zuvor auf
          Webseiten aufgerufen hat, die die Remarketing Funktion von Google
          verwenden.
        </p>
        <p>
          Nähere Informationen über die Datenverarbeitung durch Google können
          Sie den Google-Datenschutzhinweisen entnehmen:{" "}
          <a
            href="https://policies.google.com/privacy"
            target="_blank"
            rel="noreferrer"
          >
            Hier klicken
          </a>
          . Dort können Sie im Datenschutzcenter auch Ihre persönlichen
          Datenschutz-Einstellungen verändern.
        </p>
        <br />
        <p>
          <strong>Widerruf der Einwilligung:</strong>
        </p>
        <p>
          Vom Anbieter wird derzeit keine Möglichkeit für einen einfachen
          Opt-out oder ein Blockieren der Datenübertragung angeboten. Wenn Sie
          eine Nachverfolgung Ihrer Aktivitäten auf unserer Website verhindern
          wollen, widerrufen Sie bitte im Cookie-Consent-Tool Ihre Einwilligung
          für die entsprechende Cookie-Kategorie oder alle technisch nicht
          notwendigen Cookies und Datenübertragungen. In diesem Fall können Sie
          unsere Website jedoch ggfs. nicht oder nur eingeschränkt nutzen.
        </p>
        <p>
          Unsere Internetpräsenz nutzt den Terminbuchungsservice von Calendly,
          271 17th St NW, Ste 1000, Atlanta, GA 30363 USA,{" "}
          <a href="https://calendly.com/de/" target="_blank" rel="noreferrer">
            Hier klicken
          </a>
          . Wenn Sie mit uns online einen Termin vereinbaren, werden Name,
          Anschrift, E-Mail-Adresse und optional Telefonnummer von Calendly in
          unserem Auftrag erhoben und verarbeitet. Zum Schutz personenbezogener
          Daten haben wir mit Calendly einen Auftragsverarbeitungsvertrag (DPA)
          geschlossen, der hier eingesehen werden kann
          (https://calendly.com/de/pages/dpa).
        </p>
        <br />
        <h2>Calendly</h2>
        <p>
          Die Datenschutzerklärung von Calendly finden Sie hier:{" "}
          <a
            href="https://calendly.com/de/pages/privacy"
            target="_blank"
            rel="noreferrer"
          >
            Hier klicken
          </a>
        </p>
        <p>
          Rechtsgrundlage und berechtigte Interessen: Die Datenweitergabe
          erfolgt auf Basis unseres überwiegenden berechtigten Interesses (Art.
          6 Abs. 1 lit. f DSGVO) an der Sicherheit und Stabilität eines
          professionellen Terminbuchungssystems.
        </p>
        <p>
          Soweit nicht anonymisierte Daten in den USA verarbeitet werden, weisen
          wir daraufhin, dass Calendly sich vertraglich den
          EU-Standardvertragsklausen (SCC) unterworfen hat und hierdurch
          zugesichert wird, das europäische Datenschutzrecht einzuhalten.
        </p>
        <p>
          Ihre Daten werden bis zu Ihrem Widerruf gespeichert. Danach werden sie
          in der Verarbeitung eingeschränkt und bis zu drei Jahren gespeichert,
          um eine ehemals erteilte Einwilligung rechtskonform nachweisen zu
          können.
        </p>
        <p>
          Die Kalenderdaten werden sodann synchronisiert mit unserem
          hausinternen Kalendersystem.
        </p>
        <br />
        <h2>SSL-Ver­schlüsselung</h2>
        <p>
          Um die Sicherheit Ihrer Daten bei der Übertragung zu schützen,
          verwenden wir dem aktuellen Stand der Technik entsprechende
          Verschlüsselungs­verfahren (z. B. SSL) über HTTPS.
        </p>
        <br />
        <p>
          <b>Unsere Social-Media-Auftritte</b>
        </p>
        <p>
          <b>Datenverarbeitung durch soziale Netzwerke</b>
        </p>
        <p>
          Wir unterhalten öffentlich zugängliche Profile in sozialen Netzwerken.
          Die im Einzelnen von uns genutzten sozialen Netzwerke finden Sie
          weiter unten.
        </p>
        <p>
          Soziale Netzwerke wie Facebook, Twitter etc. können Ihr
          Nutzerverhalten in der Regel umfassend analysieren, wenn Sie deren
          Website oder eine Website mit integrierten Social-Media-Inhalten (z.
          B. Like-Buttons oder Werbebannern) besuchen. Durch den Besuch unserer
          Social-Media-Präsenzen werden zahlreiche datenschutzrelevante
          Verarbeitungsvorgänge ausgelöst. Im Einzelnen:
        </p>
        <p>
          Wenn Sie in Ihrem Social-Media-Account eingeloggt sind und unsere
          Social-Media-Präsenz besuchen, kann der Betreiber des
          Social-Media-Portals diesen Besuch Ihrem Benutzerkonto zuordnen. Ihre
          personenbezogenen Daten können unter Umständen aber auch dann erfasst
          werden, wenn Sie nicht eingeloggt sind oder keinen Account beim
          jeweiligen Social-Media-Portal besitzen. Diese Datenerfassung erfolgt
          in diesem Fall beispielsweise über Cookies, die auf Ihrem Endgerät
          gespeichert werden oder durch Erfassung Ihrer IP-Adresse.
        </p>
        <p>
          Mit Hilfe der so erfassten Daten können die Betreiber der
          Social-Media-Portale Nutzerprofile erstellen, in denen Ihre
          Präferenzen und Interessen hinterlegt sind. Auf diese Weise kann Ihnen
          interessenbezogene Werbung in- und außerhalb der jeweiligen
          Social-Media-Präsenz angezeigt werden. Sofern Sie über einen Account
          beim jeweiligen sozialen Netzwerk verfügen, kann die
          interessenbezogene Werbung auf allen Geräten angezeigt werden, auf
          denen Sie eingeloggt sind oder eingeloggt waren.
        </p>
        <p>
          Bitte beachten Sie außerdem, dass wir nicht alle Verarbeitungsprozesse
          auf den Social-Media-Portalen nachvollziehen können. Je nach Anbieter
          können daher ggf. weitere Verarbeitungsvorgänge von den Betreibern der
          Social-Media-Portale durchgeführt werden. Details hierzu entnehmen Sie
          den Nutzungsbedingungen und Datenschutzbestimmungen der jeweiligen
          Social-Media-Portale.
        </p>
        <br />
        <p>
          <b>Rechtsgrundlage</b>
        </p>
        <p>
          Unsere Social-Media-Auftritte sollen eine möglichst umfassende Präsenz
          im Internet gewährleisten. Hierbei handelt es sich um ein berechtigtes
          Interesse im Sinne von Art. 6 Abs. 1 lit. f DSGVO. Die von den
          sozialen Netzwerken initiierten Analyseprozesse beruhen ggf. auf
          abweichenden Rechtsgrundlagen, die von den Betreibern der sozialen
          Netzwerke anzugeben sind (z. B. Einwilligung im Sinne des Art. 6 Abs.
          1 lit. a DSGVO).
        </p>
        <br />
        <h2>Verantwortlicher und Geltendmachung von Rechten</h2>
        <p>
          Wenn Sie einen unserer Social-Media-Auftritte (z. B. Facebook)
          besuchen, sind wir gemeinsam mit dem Betreiber der
          Social-Media-Plattform für die bei diesem Besuch ausgelösten
          Datenverarbeitungsvorgänge verantwortlich. Sie können Ihre Rechte
          (Auskunft, Berichtigung, Löschung, Einschränkung der Verarbeitung,
          Datenübertragbarkeit und Beschwerde) grundsätzlich sowohl ggü. uns als
          auch ggü. dem Betreiber des jeweiligen Social-Media-Portals (z. B.
          ggü. Facebook) geltend machen.
        </p>
        <p>
          Bitte beachten Sie, dass wir trotz der gemeinsamen Verantwortlichkeit
          mit den Social-Media-Portal-Betreibern nicht vollumfänglich Einfluss
          auf die Datenverarbeitungsvorgänge der Social-Media-Portale haben.
          Unsere Möglichkeiten richten sich maßgeblich nach der
          Unternehmenspolitik des jeweiligen Anbieters.
        </p>
        <br />
        <h2>Speicherdauer</h2>
        <p>
          Die unmittelbar von uns über die Social-Media-Präsenz erfassten Daten
          werden von unseren Systemen gelöscht, sobald Sie uns zur Löschung
          auffordern, Ihre Einwilligung zur Speicherung widerrufen oder der
          Zweck für die Datenspeicherung entfällt. Gespeicherte Cookies
          verbleiben auf Ihrem Endgerät, bis Sie sie löschen. Zwingende
          gesetzliche Bestimmungen – insb. Aufbewahrungsfristen – bleiben
          unberührt.
        </p>
        <p>
          Auf die Speicherdauer Ihrer Daten, die von den Betreibern der sozialen
          Netzwerke zu eigenen Zwecken gespeichert werden, haben wir keinen
          Einfluss. Für Einzelheiten dazu informieren Sie sich bitte direkt bei
          den Betreibern der sozialen Netzwerke (z. B. in deren
          Datenschutzerklärung, siehe unten).
        </p>
        <br />
        <h2>Soziale Netzwerke im EinzelnenFacebook</h2>
        <p>
          Wir verfügen über ein Profil bei Facebook. Anbieter dieses Dienstes
          ist die Facebook Ireland Limited, 4 Grand Canal Square, Grand Canal
          Harbour, Dublin 2, Irland. Die erfassten Daten werden nach Aussage von
          Facebook auch in die USA und in andere Drittländer übertragen.
        </p>
        <p>
          Wir haben mit Facebook eine Vereinbarung über gemeinsame Verarbeitung
          (Controller Addendum) geschlossen. In dieser Vereinbarung wird
          festgelegt, für welche Datenverarbeitungsvorgänge wir bzw. Facebook
          verantwortlich ist, wenn Sie unsere Facebook-Page besuchen. Diese
          Vereinbarung können Sie unter folgendem Link einsehen:{" "}
          <a
            href="https://www.facebook.com/legal/terms/page_controller_addendum"
            rel="noreferrer"
            target="_blank"
          >
            Hier klicken
          </a>
          .
        </p>
        <br />
        <p>
          <b>
            Sie können Ihre Werbeeinstellungen selbstständig in Ihrem
            Nutzer-Account anpassen. Klicken Sie hierzu auf folgenden Link und
            loggen Sie sich ein:
          </b>{" "}
          <a
            href="https://www.facebook.com/settings?tab=ads"
            rel="noreferrer"
            target="_blank"
          >
            Hier klicken
          </a>
          .
        </p>
        <p>
          Die Datenübertragung in die USA wird auf die Standardvertragsklauseln
          der EU-Kommission gestützt. Details finden Sie hier:{" "}
          <a
            href="https://www.facebook.com/legal/EU_data_transfer_addendum"
            rel="noreferrer"
            target="_blank"
          >
            Hier klicken
          </a>{" "}
          und{" "}
          <a
            href="https://de-de.facebook.com/help/566994660333381"
            rel="noreferrer"
            target="_blank"
          >
            Hier klicken
          </a>
          .
        </p>
        <p>
          Details entnehmen Sie der Datenschutzerklärung von Facebook:{" "}
          <a
            href="https://www.facebook.com/about/privacy/"
            rel="noreferrer"
            target="_blank"
          >
            Hier klicken
          </a>
          .
        </p>
        <hr className="wp-block-separator" />

        <br />
        <p>
          <strong>
            Information über Ihr Widerspruchs­recht nach Art. 21 DSGVO
          </strong>
        </p>
        <br />
        <br />
        <h2>Einzelfallbezogenes Widerspruchs­recht</h2>
        <p>
          Sie haben das Recht, aus Gründen, die sich aus Ihrer besonderen
          Situation ergeben, jederzeit gegen die Verarbeitung Sie betreffender
          personenbezogener Daten, die aufgrund Art. 6 Abs. 1 lit. f DSGVO
          (Datenverarbeitung auf der Grundlage einer Interessenabwägung)
          erfolgt, Widerspruch einzulegen; dies gilt auch für ein auf diese
          Bestimmung gestütztes Profiling im Sinne von Art. 4 Nr. 4 DSGVO.
        </p>
        <p>
          Legen Sie Widerspruch ein, werden wir Ihre personenbezogenen Daten
          nicht mehr verarbeiten, es sei denn, wir können zwingende
          schutzwürdige Gründe für die Verarbeitung nachweisen, die Ihre
          Interessen, Rechte und Freiheiten überwiegen, oder die Verarbeitung
          dient der Geltendmachung, Ausübung oder Verteidigung von
          Rechtsansprüchen.
          <br />
          <br />
        </p>
        <br />
        <h2>Empfänger eines Widerspruchs</h2>
        <p>
          AROUND IT-Security GmbH
          <br />
          Weingärterstr. 17
          <br />
          74912 Kirchardt
          <br />
          info@aroundsec.de
        </p>
        <hr className="wp-block-separator" />

        <br />
        <h2>Änderung unserer Datenschutz­&shy;bestimmungen</h2>
        <p>
          Wir behalten uns vor, diese Datenschutzerklärung anzupassen, damit sie
          stets den aktuellen rechtlichen Anforderungen entspricht oder um
          Änderungen unserer Leistungen in der Datenschutzerklärung umzusetzen,
          z.B. bei der Einführung neuer Services. Für Ihren erneuten Besuch gilt
          dann die neue Datenschutzerklärung.
        </p>
        <br />
        <h2>Fragen an den Datenschutz­beauftragten</h2>
        <p>
          Wenn Sie Fragen zum Datenschutz haben, schreiben Sie uns bitte eine
          E-Mail oder wenden Sie sich direkt an die für den Datenschutz
          verantwortliche Person in unserer Organisation:
        </p>
        <p>
          <em>
            Die Datenschutzerklärung wurde mithilfe der activeMind AG erstellt,
            den Experten für{" "}
            <a
              href="https://www.activemind.de/datenschutz/datenschutzbeauftragter/"
              target="_blank"
              rel="noreferrer"
            >
              externe Datenschutzbeauftragte
            </a>{" "}
            (Version #2020-09-30).
          </em>
        </p>
        <p></p>
      </div>
    </section>
  </Layout>
)

export default Datenschutz
